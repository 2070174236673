import React from "react";

const LandingArrowVLeft = () => (
  <svg width="38" height="65" viewBox="0 0 38 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8351 63.4976C20.7457 64.0426 20.2315 64.412 19.6865 64.3227L10.805 62.8669C10.26 62.7775 9.89058 62.2633 9.97992 61.7183C10.0693 61.1733 10.5835 60.8039 11.1285 60.8932L19.0232 62.1872L20.3172 54.2926C20.4065 53.7476 20.9208 53.3782 21.4658 53.4675C22.0108 53.5569 22.3802 54.0711 22.2909 54.6161L20.8351 63.4976ZM22.0425 2.30934C17.1989 5.85739 11.2456 13.1545 9.29818 23.4428C7.35768 33.6949 9.37294 47.0391 20.6604 62.7524L19.0361 63.9192C7.48336 47.8367 5.27468 33.9458 7.33308 23.0709C9.3846 12.2323 15.6529 4.51068 20.8606 0.695915L22.0425 2.30934Z"
      fill="#2369BD"/>
  </svg>

);

export default LandingArrowVLeft;
