import React from "react";

const LandingBilling = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6420_3554)">
      <mask id="mask0_6420_3554" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="80"
            height="80">
        <path d="M80 0H0V80H80V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_6420_3554)">
        <path d="M58.5999 39V5.40002H8.3999V72.6H47.2999V70.4H10.6999V7.60002H56.2999V39H58.5999Z" fill="#001450"/>
        <mask id="mask1_6420_3554" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="36" y="34" width="42"
              height="31">
          <path d="M77.3 34.7001H36.2V64.9001H77.3V34.7001Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_6420_3554)">
          <path
            d="M67.9 35.8001L70.5999 38.5001L71.0999 39.0001L70.5999 39.5001L59 51.4001L58.5 51.9001L58 51.4001L52.7999 46.0001C52.7999 46.0001 52.6999 46.0001 52.5999 46.0001L36.2 62.8001L38.0999 64.8001L52.2 50.4001L52.7 49.9001L53.2 50.4001L58.4 55.8001H58.5999L72.5999 41.5001L73.0999 41.0001L73.5999 41.5001L76.3 44.2001L77.4 34.6001L68 35.8001H67.9Z"
            fill="#D5000A"/>
        </g>
        <path d="M35.1999 37.5H32.3999V44.7H35.1999V37.5Z" fill="#001450"/>
        <path
          d="M33.8999 40.1001C29.9999 40.1001 26.8999 37.4001 26.8999 34.2001V32.9001H29.4999V34.2001C29.4999 36.0001 31.4999 37.5001 33.8999 37.5001C36.2999 37.5001 38.2999 36.0001 38.2999 34.2001C38.2999 32.4001 36.8999 31.0001 34.8999 31.0001H32.8999C29.6999 31.0001 26.8999 28.3001 26.8999 25.1001C26.8999 21.9001 29.9999 19.2001 33.8999 19.2001C37.7999 19.2001 40.8999 21.9001 40.8999 25.1001V26.4001H38.2999V25.1001C38.2999 23.3001 36.2999 21.8001 33.8999 21.8001C31.4999 21.8001 29.4999 23.3001 29.4999 25.1001C29.4999 26.9001 30.8999 28.3001 32.8999 28.3001H34.8999C38.0999 28.3001 40.8999 31.0001 40.8999 34.2001C40.8999 37.4001 37.7999 40.1001 33.8999 40.1001Z"
          fill="#001450"/>
        <path d="M35.1999 14.9H32.5999V21.8H35.1999V14.9Z" fill="#001450"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6420_3554">
        <rect width="80" height="80" fill="white"/>
      </clipPath>
    </defs>
  </svg>


);

export default LandingBilling;
