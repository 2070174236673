import React from "react";

const LandingArrowHBottom = () => (
  <svg width="125" height="72" viewBox="0 0 125 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M122.019 32.0637C122.836 32.1977 123.39 32.9691 123.256 33.7866L121.073 47.1088C120.939 47.9264 120.167 48.4805 119.35 48.3465C118.532 48.2125 117.978 47.4411 118.112 46.6236L120.053 34.7816L108.211 32.8405C107.394 32.7065 106.84 31.9352 106.974 31.1177C107.108 30.3001 107.879 29.746 108.696 29.88L122.019 32.0637ZM3.18427 29.5512C10.117 39.0152 24.348 50.6188 44.402 54.4146C64.4016 58.2001 90.3757 54.2532 120.901 32.3257L122.651 34.7623C91.5721 57.0876 64.778 61.3246 43.8441 57.3622C22.9647 53.4102 8.09691 41.3342 0.764136 31.324L3.18427 29.5512Z"
      fill="#2369BD"/>
  </svg>

);

export default LandingArrowHBottom;
