import React from "react";

const LandingArrowHTop = () => (
  <svg width="125" height="72" viewBox="0 0 125 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M122.019 39.9363C122.836 39.8023 123.39 39.0309 123.256 38.2134L121.073 24.8912C120.939 24.0736 120.167 23.5195 119.35 23.6535C118.532 23.7875 117.978 24.5589 118.112 25.3764L120.053 37.2184L108.211 39.1595C107.394 39.2935 106.84 40.0648 106.974 40.8823C107.108 41.6999 107.879 42.254 108.696 42.12L122.019 39.9363ZM3.18427 42.4488C10.117 32.9848 24.348 21.3812 44.402 17.5854C64.4016 13.7999 90.3757 17.7468 120.901 39.6743L122.651 37.2377C91.5721 14.9124 64.778 10.6754 43.8441 14.6378C22.9647 18.5898 8.09691 30.6658 0.764136 40.676L3.18427 42.4488Z"
      fill="#2369BD"/>
  </svg>

);

export default LandingArrowHTop;
