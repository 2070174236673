import React from "react";

const LandingArrowVRight = () => (
  <svg width="38" height="65" viewBox="0 0 38 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3262 63.4976C16.4155 64.0426 16.9297 64.412 17.4748 64.3227L26.3562 62.8669C26.9012 62.7775 27.2706 62.2633 27.1813 61.7183C27.092 61.1733 26.5777 60.8039 26.0327 60.8932L18.1381 62.1872L16.844 54.2926C16.7547 53.7476 16.2404 53.3782 15.6954 53.4675C15.1504 53.5569 14.781 54.0711 14.8704 54.6161L16.3262 63.4976ZM15.1188 2.30934C19.9623 5.85739 25.9157 13.1545 27.863 23.4428C29.8035 33.6949 27.7883 47.0391 16.5008 62.7524L18.1252 63.9192C29.6779 47.8367 31.8865 33.9458 29.8281 23.0709C27.7766 12.2323 21.5083 4.51068 16.3006 0.695915L15.1188 2.30934Z"
      fill="#2369BD"/>
  </svg>


);

export default LandingArrowVRight;
